<template>
  <v-flex>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value="dates"
      transition="scale-transition"
      offset-y
      min-width="auto"
      max-width="300"
      @modify="updateDateRangeText"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formattedDatesText"
          label="Calendário"
          prepend-icon="mdi-calendar-range"
          readonly
          v-bind="attrs"
          v-on="on"
          lazy-validation
          :rules="[rules.dateRangeRequired]"
        >
        </v-text-field>
      </template>
      <v-date-picker
        v-model="dates"
        range
        no-title
        @change="handleDateChange"
        :allowed-dates="allowedDates"
      >
        <v-row>
          <v-col cols="12" sm="12" class="d-flex justify-end mt-3">
            <v-btn text color="primary" @click="clearDates">Limpar</v-btn>
            <v-btn text color="primary" @click="saveDate" :disabled="!valid">Ok</v-btn>
          </v-col>
        </v-row>
      </v-date-picker>
    </v-menu>
  </v-flex>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    clear: {
      type: Boolean,
      default: false
    },
    allowedDates: {
      type: Function,
      default: () => true
    },
    minDate: { type: String, default: () => moment().subtract(30, 'days').format('YYYY-MM-DD') },
    maxDate: { type: String, default: () => moment().format('YYYY-MM-DD') }
  },
  data() {
    return {
      dates: [],
      menu: false,
      formattedDates: ['DD/MM/YYYY', 'DD/MM/YYYY'],
      formattedDatesText: '',
      valid: true,
      errorMessage: '',
      rules: {
        dateRangeRequired: value => !!value || 'A data de início e fim são obrigatórias',
        required: value => !!value || 'Este campo é obrigatório'
      },
    };
  },
  watch: {
    clear() {
      this.clearDates();
      this.$emit('clearComplete');
    }
  },
  mounted() {
    this.dates = [this.minDate, this.maxDate];
    this.updateDateRangeText();
  },
  methods: {
    handleDateChange(value) {
      this.validateDateRange();
      if (value.length === 2) {
        const [minDate, maxDate] = value;
        if (moment(minDate).isAfter(maxDate)) {
          this.dates = [maxDate, minDate];
        } else {
          this.dates = [minDate, maxDate];
        }
      }
      this.$emit('input', this.dates);
    },
    clearDates() {
      this.dates = [];
      this.updateDateRangeText();
    },
    saveDate() {
      if (this.valid) {
        this.menu = false;
        this.updateDateRangeText();
        this.$emit('modify', this.dates);
      }
    },
    updateDateRangeText() {
      this.validateDateRange();
      this.formattedDates = [
        this.dates[0] ? moment(this.dates[0]).format('DD/MM/YYYY') : null,
        this.dates[1] ? moment(this.dates[1]).format('DD/MM/YYYY') : null
      ];
      this.formattedDatesText = this.valid
        ? `${this.formattedDates[0]} - ${this.formattedDates[1]}`
        : '';
    },
    validateDateRange() {
      const [startDate, endDate] = this.dates;
      if (startDate && endDate) {
        this.valid = true;
        this.errorMessage = '';
      } else {
        this.valid = false;
        this.errorMessage = 'A data de início e fim são obrigatórias.';
      }
    },
  }
};
</script>