<template>
  <v-card class="mb-16">
    <feedback ref="feedback"></feedback>
    <MissingProductsFilter
      @modify="updateFilters"
      ref="filter"
      @request-excel="getExcel"
    ></MissingProductsFilter> 
    
    <v-card-title>
      <v-spacer></v-spacer>
      <v-form ref="form" lazy-validation v-model="search.valid" @submit.prevent="getMissingProducts">
        <v-text-field
          class="mr-2 pt-0"
          v-model="search.text"
          append-icon="search"
          label="Pesquisar"
          single-line
          hide-details
          clearable
          filled
          rounded
          dense
          @click:clear="search.text = ''; getMissingProducts()"
          @click:append="options.page = 1; getMissingProducts()"
          @input="options.page = 1"
        ></v-text-field>
      </v-form>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      :loading="loading.missingProducts"
      :headers="headers"
      :items="missingProducts"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{ itemsPerPageOptions: [10, 20, 30, 50, 100] }"
    >    
    <template v-slot:[`item.barCode`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ item.barCode && item.barCode.length > 16 ? item.barCode.slice(0, 16) + '...' : item.barCode || '-' }}
          </span>
        </template>
        <span>{{ item.barCode }}</span>
      </v-tooltip>
    </template>
      <template v-slot:[`item.productDescription`]="{ item }">
        {{ item.productDescription === null ? '-' : item.productDescription }}
      </template>
      <template v-slot:[`item.category`]="{ item }">
        {{ item.category === null ? '-' : item.category }}
      </template>
      <template v-slot:[`item.productCode`]="{ item }">
        {{ item.productCode !== null ? item.productCode : '-' }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ formatDate(item.date) }}</span>
      </template>
      <template v-slot:[`item.pointOfSaleName`]="{ item }">
        {{ item.pointOfSaleName === null ? '-' : item.pointOfSaleName }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import MissingProductsFilter from "@/components/filters/MissingProductsFilter";
import moment from 'moment';
import { MissingProducts } from "@/api/agent"; 
import download from '@/components/Download';

export default {
  data() {
    return {
      missingProducts: [],
      headers: [
        { text: 'Cód. de Barras ', align: 'left', sortable: true, value: 'barCode' },
        { text: 'Desc. do Produto', align: 'left', sortable: true, value: 'productDescription' },
        { text: 'Categoria', align: 'left', sortable: true, value: 'category' },
        { text: 'Cód do Produto', align: 'left', sortable: true, value: 'productCode' },
        { text: 'Data da leitura', align: 'left', sortable: true, value: 'date' },
        { text: 'PDV Lido', align: 'left', sortable: false, value: 'pointOfSaleName' },
      ],
      loading: {
        missingProducts: true,
      },
      form: {},
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true],
        mustSort: true,
      },
      search: {
        text: '',
        valid: true,
      },
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getMissingProducts();
        }
      },
    },
  },
  methods: {
    updateFilters(form) {
      if (form.minDate != null || form.maxDate != null || form.pointOfSaleId != null) {
        this.options.page = 1;
      }
      this.form = form;
      this.getMissingProducts();
    },
    getMissingProducts() {
      this.loading.missingProducts = true;
      let params = toApiPagination(this.options, this.search.text);
      this.insertFiltersFromForm(params);
      MissingProducts.getMissingProducts(params)
        .then((response) => {
          this.loading.missingProducts = false;
          this.missingProducts = response.items;
          this.totalItems = response.totalItems;
        })
        .catch(() => {
          this.loading.missingProducts = false;
        });
    },
    insertFiltersFromForm(params) {
      const form = this.form;
      params.pointOfSaleId = form.pointOfSaleId;
      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      return params;
    },
    formatDate(date) {
      return moment(date).isValid() ? moment(date).format('DD/MM/YYYY HH:mm:ss') : "";
    },
    async getExcel() {
      this.loading.excel = true;
      try {
        const response = await MissingProducts.getExcel(this.form)
        download(response, `Produtos_nao_encontrados.xlsx`);
        this.$refs.filter.notifyExcelDownloaded();
      } catch (error) {
        this.$refs.feedback.handleError('Erro ao baixar o Excel', error);         
      } finally {
        this.$refs.filter.notifyExcelDownloaded();
      }
    },
  },
  components: { MissingProductsFilter },
};
</script>