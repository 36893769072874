
import Vue from 'vue';
import {shouldRefreshData, toApiPagination} from '@/components/TableOptionsComparator';
import {formatDateTimeMinutes} from '@/utility/TextFormatting';
import BulkProgressDialog from "@/components/common/BulkProgressDialog.vue";
import PointOfSalesSelectorDialog from "@/components/common/PointOfSalesSelectorDialog.vue";
import {PointOfSale} from "@/types/PointOfSale";
import {PriceTable} from '@/types/PriceTable';
import PriceTableCreationDialog from "@/views/priceTables/components/PriceTableCreationDialog.vue";
import RemoveItemDialog from "@/components/common/RemoveItemDialog.vue";
import PriceTablesHelp from "@/views/priceTables/helpers/PriceTablesHelp.vue";
import PlanogramDraftFinishDialog from "@/components/common/PlanogramDraftFinishDialog.vue";
import PlanogramNextActivateDialog from "@/components/common/PlanogramNextActivateDialog.vue";
import agent from "@/api/agent";
import DivergencesDialog from "@/views/priceTables/components/DivergencesDialog.vue";
import {SingleOperationBulkResult} from "@/types/common/BulkOperation";

interface KeyValue {
  [key: string]: string;
}

export default Vue.extend({
  components: {
    PlanogramDraftFinishDialog, PriceTablesHelp, RemoveItemDialog,
    PriceTableCreationDialog, BulkProgressDialog, PointOfSalesSelectorDialog,
    DivergencesDialog, PlanogramNextActivateDialog
  },
  data() {
    return {
      priceTables: [] as PriceTable[] | undefined,
      translation: {
        table: {
          item: {
            name: this.$t('tables.headers.name').toString(),
            type: {
              PriceOnly: this.$t('tables.headers.price').toString(),
              Markup: this.$t('tables.headers.markUp').toString(),
            } as KeyValue,
            lastUpdated: this.$t('tables.headers.lastUpdated').toString(),
            activate: this.$t('tables.headers.applyPriceTableOnPointOfSale').toString(),
            remove: this.$t('tables.headers.removePriceTable').toString(),
          },
          noData: this.$t('tables.noData').toString(),
        },
      },
      headers: [
        {text: this.$t('tables.headers.id'), align: 'left', value: 'id'},
        {text: this.$t('tables.headers.name'), align: 'left', value: 'name'},
        {text: this.$t('tables.headers.type'), align: 'left', value: 'type'},
        {text: this.$t('tables.headers.lastUpdated'), align: 'left', value: 'lastUpdated'},
        {text: this.$t('tables.headers.items'), align: 'left', value: 'totalPriceTableItems'},
        {text: this.$t('tables.headers.activate'), align: 'left', sortable: false, value: 'activate'},
        {text: this.$t('tables.headers.remove'), align: 'left', sortable: false, value: 'delete'}
      ],
      loading: {
        priceTables: true
      },
      search: {
        valid: true,
        text: '' || null,
      },
      pointOfSalesSelectorDialog: {
        enabled: false,
      },
      bulkProgressDialog: {
        enabled: false,
        progress: 0,
        infoMessages: [],
        firstOption: {
          text: this.$t('planogram.draft.action.finish').toString(),
          enabled: false
        },
        secondOption: {
          text: this.$t('planogram.draft.action.activate').toString(),
          enabled: false
        },
      },
      priceTableToRemove: {} as PriceTable,
      removeItemDialog: {
        enabled: false,
        name: '',
        description: this.$t('priceTable.action.remove.description').toString(),
        finished: false
      },
      createPriceTableDialog: {
        enabled: false,
      },
      planogramDraftFinishDialog: {
        enabled: false,
      },
      planogramNextActivateDialog: {
        enabled: false,
      },
      posIdsCandidatesToFinish: [] as number[],
      posIdsCandidatesToActivate: [] as number[],
      priceTableIdToApply: 0,
      editPriceTableName: '',
      pointOfSalesToAdd: [] as PointOfSale[] | undefined,
      apply: {
        addFromPriceTable: false,
        removeFromPlanogram: false
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['lastUpdated'],
        sortDesc: [true],
        mustSort: true
      },
      helpDialog: {
        enabled: false,
      },
      translations: {
        action: {
          new: this.$t('priceTable.action.new').toString(),
          create: this.$t('priceTable.action.create').toString(),
          edit: this.$t('priceTable.action.edit').toString(),
          remove: {
            action: this.$t('priceTable.action.remove.action').toString(),
            description: this.$t('priceTable.action.remove.description').toString(),
          },
        },
        response: {
          fetch: {
            error: this.$t('priceTable.response.fetch.error').toString(),
          },
          create: {
            success: (translation: object): string => {
              const translationPath = Object.keys(translation).length === 1 ? 'success' : 'successPlanogram';
              return this.$t(`priceTable.response.create.${translationPath}`, translation).toString();
            },
            error: this.$t('priceTable.response.create.error').toString(),
          },
          edit: {
            success: (translation: object): string => this.$t('priceTable.response.edit.success', translation).toString(),
            error: this.$t('priceTable.response.edit.error').toString()
          },
          remove: {
            success: (translation: object): string => this.$t('priceTable.response.remove.success', translation).toString(),
            error: this.$t('priceTable.response.remove.error').toString(),
          },
        },
        table: {
          header: {
            id: this.$t('tables.headers.id').toString(),
            name: this.$t('tables.headers.name').toString(),
            type: this.$t('tables.headers.type').toString(),
            lastUpdated: this.$t('tables.headers.lastUpdated').toString(),
            items: this.$t('tables.headers.items').toString(),
            activate: this.$t('tables.headers.activate').toString(),
            remove: this.$t('tables.headers.remove').toString(),
          },
          item: {
            name: this.$t('tables.headers.name').toString(),
            type: {
              PriceOnly: this.$t('tables.headers.price').toString(),
              Markup: this.$t('tables.headers.markUp').toString(),
            } as KeyValue,
            lastUpdated: this.$t('tables.headers.lastUpdated').toString(),
            activate: this.$t('tables.headers.applyPriceTableOnPointOfSale').toString(),
            remove: this.$t('tables.headers.removePriceTable').toString(),
          },
          noData: this.$t('tables.noData').toString(),
        },
        planogram: {
          draft: {
            action: {
              create: this.$t('planogram.draft.action.create').toString(),
            },
            advanced: {
              option: this.$t('planogram.draft.advanced.option').toString(),
              action: {
                add: this.$t('planogram.draft.advanced.action.add').toString(),
                remove: this.$t('planogram.draft.advanced.action.remove').toString()
              }
            },
            response: {
              finish: {
                success: (translation: object): string => this.$t('planogram.draft.response.apply.success', translation).toString(),
                error: (translation: object): string => this.$t('planogram.draft.response.apply.error', translation).toString(),
              }
            }
          },
          response: {
            apply: {
              success: (translation: object): string => this.$t('planogram.response.apply.success', translation).toString(),
              error: (translation: object): string => this.$t('planogram.response.apply.error', translation).toString(),
              reject: this.$t('planogram.response.apply.reject').toString(),
            },
            activate: {
              success: (translation: object): string => this.$t('planogram.response.activate.success', translation).toString(),
              error: (translation: object): string => this.$t('planogram.response.activate.error', translation).toString(),
            }
          }
        },
        button: {
          search: this.$t('buttons.search').toString(),
          cancel: this.$t('buttons.cancel').toString(),
          save: this.$t('buttons.save').toString(),
        },
      },
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getPriceTables();
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.getPriceTables();
  },
  methods: {
    /**Price Table Handling Methods.*/
    getPriceTables() {
      this.loading.priceTables = true;
      let params = toApiPagination(this.options, this.search.text);
      agent.PriceTables.list(params)
          .then((response) => {
            this.priceTables = response.items;
            this.totalItems = response.totalItems;
          })
          .catch((error) => {
            this.handleError(this.translations.response.fetch.error, error);
          })
          .finally(() => this.loading.priceTables = false);
    },
    updatePriceTableName(priceTable: PriceTable) {
      agent.PriceTables.changePriceTableName(priceTable.id, this.editPriceTableName)
          .then(() => {
            const translation = { priceTableNamePrevious: priceTable.name, priceTableName: this.editPriceTableName };
            this.handleSuccess(this.translations.response.edit.success(translation));
          })
          .catch((error) => {
            this.handleError(this.translations.response.edit.error, error);
          })
          .finally(() => {
            this.editPriceTableName = '';
            this.getPriceTables();
          });
    },
    onRemoveItemConfirmation() {
      this.removeItemDialog.finished = false;
      agent.PriceTables.delete(this.priceTableToRemove.id)
          .then(() => {
            const entryIndex = this.priceTables!.indexOf(this.priceTableToRemove);
            this.priceTables!.splice(entryIndex, 1);
            const translation = { priceTableName: this.priceTableToRemove.name };
            this.handleSuccess(this.translations.response.remove.success(translation));
          })
          .catch((error) => {
            this.handleError(this.translations.response.remove.error, error);
          })
          .finally(() => {
            this.removeItemDialog.finished = true;
          });
    },
    openRemoveItemDialog(priceTable: PriceTable) {
      this.priceTableToRemove = priceTable;
      this.removeItemDialog.name = priceTable.name;
      this.removeItemDialog.enabled = true;
    },
    openNameTextEditor(priceTable: PriceTable) {
      this.editPriceTableName = priceTable.name;
    },
    cancelNameTextEditor() {
      this.editPriceTableName = '';
    },
    openPointOfSaleDialog: function (priceTable: PriceTable) {
      this.priceTableIdToApply = priceTable.id;
      this.pointOfSalesSelectorDialog.enabled = true;
    },
    openPriceTableItems: function (priceTable: PriceTable) {
      const path = '/' + this.$t('path.namePriceTables') + '/' + priceTable.id;
        this.$router.push(path);
    },
    onCreatedPriceTable(name: string, planogramId: number) {
      let translation;
      if (!planogramId) {
        translation = { priceTableName: name };
      } else {
        translation = { priceTableName: name, planogramId: planogramId };
      }
      this.handleSuccess(this.translations.response.create.success(translation));
      this.getPriceTables();
    },
    onPointOfSalesSelection(pointOfSales: PointOfSale[]) {
      this.ApplyPriceTableToPointOfSales(pointOfSales);
    },

    /**Planogram Handling methods.*/
    ApplyPriceTableToPointOfSales: async function (pointOfSales: PointOfSale[]) {
      const numberOfReqsParallel = pointOfSales.length;
      const elementPercentage = 100 / numberOfReqsParallel;
      const numberOfElements = Math.ceil(pointOfSales.length / numberOfReqsParallel);
      const requests = pointOfSales.map((pos) => pos.id);
      const chunkedPos = this.partition(requests, numberOfElements);
      const chunkedRequests = chunkedPos.map(posList => {
        return {
          posIds: posList,
          addFromPriceTable: this.apply.addFromPriceTable,
          removeFromPlanogram: this.apply.removeFromPlanogram
        }
      });

      this.bulkProgressDialog.enabled = true;
      this.bulkProgressDialog.progress = 0;

      const promiseRequests = [...Array(numberOfReqsParallel).keys()]
          .map(x =>
              agent.PriceTables.bulkApply(this.priceTableIdToApply, chunkedRequests[x])
                  .finally(() => {
                    this.bulkProgressDialog.progress += elementPercentage;
                  })
          );

      await Promise.allSettled(promiseRequests)
          .then((response) => {
            let bulkResult: SingleOperationBulkResult[];
            let successFiltered = response.filter(x => x.status == "fulfilled");

            bulkResult = successFiltered.map(x => (x as PromiseFulfilledResult<any>).value).flatMap(it => it.results);
            let successPdvIds = bulkResult.map(br => br.entityId);
            let toApplyPdvIds = pointOfSales.map(pos => pos.id);
            let rejectsIds = toApplyPdvIds.filter(x => !successPdvIds.includes(x));

            if (rejectsIds) {
              bulkResult.push(...this.createRejectedResults(rejectsIds));
            }

            let draftCreatedResults = bulkResult.filter(br => {
              return br.error == null;
            });
            this.posIdsCandidatesToFinish = draftCreatedResults.map(r => r.entityId);
            this.bulkProgressDialog.firstOption.enabled = draftCreatedResults.length > 0;
            this.handleBulkResult(bulkResult,
                (entityId: string) => this.translations.planogram.response.apply.success({pointOfSaleId: entityId}),
                (entityId: string, error: string) => this.translations.planogram.response.apply.error({pointOfSaleId: entityId, errorMessage: error}),
            );
          }).finally(() => {
            this.bulkProgressDialog.progress = 100;
          });
    },
    onFinishDraftsConfirmation() {
      this.bulkProgressDialog.progress = 0;
      this.axios.put('api/planograms/bulk/finish', null, {params: {posIds: this.posIdsCandidatesToFinish}})
          .then((response) => {
            this.handleBulkResult(response.data.results,
                (entityId) => this.translations.planogram.draft.response.finish.success({pointOfSaleId: entityId}),
                (entityId, error) => this.translations.planogram.draft.response.finish.error({pointOfSaleId: entityId, errorMessage: error}),
            );

            let finishedResults = response.data.results.filter((r: any) => {
              return r.error == null;
            });
            this.posIdsCandidatesToActivate = finishedResults.map((r: any) => r.entityId);
            this.bulkProgressDialog.secondOption.enabled = finishedResults.length > 0;
          })
          .finally(() => {
            this.bulkProgressDialog.firstOption.enabled = false;
            this.bulkProgressDialog.progress = 100;
          });
    },
    async onActivateNextConfirmation() {
      const numberOfReqsParallel = this.posIdsCandidatesToActivate.length;
      const elementPercentage = 100 / numberOfReqsParallel;
      const numberOfElements = Math.ceil(this.posIdsCandidatesToActivate.length / numberOfReqsParallel);
      const requests = this.posIdsCandidatesToActivate;
      const chunkedRequests = this.partition(requests, numberOfElements);

      this.bulkProgressDialog.progress = 0;

      const promiseRequests = [...Array(numberOfReqsParallel).keys()]
          .map(x =>
              this.axios.put('api/planograms/bulk/activate', null, {params: {posIds: chunkedRequests[x]}})
                  .finally(() => {
                    this.bulkProgressDialog.progress += elementPercentage;
                  })
          );
      await Promise.allSettled(promiseRequests)
          .then((response) => {
            const bulkResult = {results: null as any};
            bulkResult.results = response.map((x: any) => x.value.data).flatMap(it => it.results);
            this.handleBulkResult(bulkResult.results,
                (entityId) => this.translations.planogram.response.activate.success({pointOfSaleId: entityId}),
                (entityId, error) => this.translations.planogram.response.activate.error({pointOfSaleId: entityId, errorMessage: error})
            );
          }).finally(() => {
            this.bulkProgressDialog.secondOption.enabled = false;
            this.bulkProgressDialog.progress = 100;
          });
    },
    partition(array: number[], n: number): number[][] {
      return array.length ? [array.splice(0, n)].concat(this.partition(array, n)) : [];
    },
    createRejectedResults(rejectsIds: number[]): SingleOperationBulkResult[] {
      return rejectsIds.map((id: number) => {
        return {
          entityId: id,
          success: false,
          error: this.translations.planogram.response.apply.reject,
        } as SingleOperationBulkResult;
      });
    },

    /**Auxiliary.*/
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
    handleBulkResult: function (bulkResult: any, successMessage: (entityId: string) => string, errorMessage: (entityId: string, error: string) => string) {
      this.bulkProgressDialog.infoMessages = bulkResult.map((r: any) => {
        return {
          entityId: r.entityId,
          type: r.success ? 'success' : 'error',
          visible: true,
          text: r.success ? successMessage(r.entityId) : errorMessage(r.entityId, r.error),
          divergentProducts: r.divergentProducts
        };
      });
    },
    formatDateTimeMinutes
  }
})
;
