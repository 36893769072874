export default {
  pointOfSale: {
    data: {
      id: "Ponto de venda {pointOfSaleId}",
      title: {
        pointOfSale: "Ponto de Venda",
        pointsOfSale: "Pontos de Venda"
      },
    },
  }
}
