export default {
  filters: {
    labels: {
      calendar: "Calendario",
      months: {
        january: "Enero",
        february: "Febrero",
        march: "Marzo",
        april: "Abril",
        may: "Mayo",
        june: "Junio",
        july: "Julio",
        august: "Agosto",
        september: "Septiembre",
        october: "Octubre",
        november: "Noviembre",
        december: "Diciembre"
      },
    },
    messages: {
      selectMonth: "Seleccione un mes"
    },
  },
}
