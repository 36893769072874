export default {
    returnToPrevious: 'Volver',
    customer: 'Cliente',
    environmentId: 'ID de Entorno',
    email: 'Correo electrónico',
    permissions: 'Permisos',
    favoritePage: 'Agregar a favoritos',
    removeFromFavorites: 'Quitar de favoritos',
    myAccount: 'MI CUENTA',
    newOrder: 'Nuevo Orden',
    changeOwnership: 'CAMBIAR TITULARIDAD',
    requestChangeOwnership: 'Transferir Puntos de Venta',
    viewTransferRequests: 'Aprobar Transferencias',
    myBills: 'Mis Cuentas',
    support: 'Apoyo',
    changeTenant: 'Alterar tenant',
    changeConfirmation: 'Alterar',
    myProfile: 'Mi Perfil',
};
