export default {
    removePickListsPickListTable: 'Remover pick lists',
    selectAtLeastOnePickList: 'Selecione pelo menos uma pick list',
    excelPickListTable: 'Excel',
    synchronizationWithManagementSystem: 'Sincronizar pick-lists com o sistema de gestão',
    noResultsPickListTable: 'Sem resultados',    
    idPickListTable: 'ID Pick List',
    description: 'Descrição',
    primaryPickListTable: 'Principal',
    customerPickListTable: 'Cliente',
    locationPickListTable: 'Local',
    specificLocationPickListTable: 'Local Específico',
    pointOfSalePickListTable: 'PDV',
    machinePickListTable: 'Máquina',
    updatedActionPickListTable: 'Atualizada em',
    syncWithOmiePickListTable: 'Sincronização com sistema de gestão Omie',
    erpManagementSystem: 'ERP',
    removeConfirmationPickListTable: 'Tem certeza que deseja remover',
    pickListPickListTable: ' pick ',
    noPickListTable: 'Não',
    removePickListTable: 'Remover',
    successRemovePickListTable: 'Pick list ',
    removeSuccessPickListTable: ' removida',
    errorRemovePickListTable: 'Não foi possível remover a pick list ',
    synchronizationStarted: 'A pick-list',
    synchronizationStartedPickListTable: ' está sendo sincronizada com o Omie e em alguns instantes estará disponível.',
    syncErrorPickListTable: 'Não foi possível sincronizar a pick-list',
    generateReportErrorPickListTable: 'Não foi possível gerar o relatório',
    createdSuccessPickListTable: ' criada para o PDV ',
    createdErrorPickListTable: 'Não foi possível criar a pick list para o PDV ',
    syncSuccessPickListTable: 'As pick-lists estão sendo sincronizadas com o ERP AMLabs.',
    syncErrorPickList: 'Não foi possível sincronizar as pick-lists.',
    editPickListTable: '(editada)',
    synchronizationPickListTable: 'Sincronização',
};
  