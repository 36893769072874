import ButtonEs from './ButtonEs';
import RemoveDialogEs from './RemoveDialogEs';
import PlanogramEs from './PlanogramEs';
import PriceTablesEs from './PriceTablesEs';
import PointOfSaleEs from './PointOfSaleEs';
import FranchiseeEs from './FranchiseeEs';
import TablesEs from './TablesEs';
import ReportEs from './ReportEs';
import FiltersEs from './FiltersEs';

export default Object.assign({}, 
  PriceTablesEs,
  ButtonEs,
  PlanogramEs,
  RemoveDialogEs,
  PointOfSaleEs,
  FranchiseeEs,
  TablesEs,
  ReportEs,
  FiltersEs,
);