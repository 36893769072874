export default {
  pointOfSale: {
    data: {
      id: "Point of sale {pointOfSaleId}",
      title: {
        pointOfSale: "Point of Sale",
        pointsOfSale: "Points of Sale"
      }
    }
  },
};
