export default {
  buttons: {
    search: "Search",
    cancel: "Cancel",
    save: "Save",
    close: "Close",
    apply: "Apply",
    create: "Create",
    import: "Import",
    add: "Add",
    excel: "Excel",
    yes: "Yes",
    no: "No",
    finish: "Finish",
    remove: "Remove",
    activate: "Activate"
  }
};
