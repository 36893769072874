export default {
  buttons: {
    cancel: "Cancelar",
    search: "Procurar",
    save: "Salvar",
    close: "Fechar",
    apply: "Aplicar",
    create: "Criar",
    import: "Importar",
    add: "Adicionar",
    excel: "Excel",
    yes: "Sim",
    no: "Não",
    finish: "Finalizar",
    remove: "Remover",
    activate: "Ativar",
  },
};
