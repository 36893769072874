import ButtonEn from './ButtonEn';
import RemoveDialogEn from './RemoveDialogEn';
import PlanogramEn from './PlanogramEn';
import PriceTablesEn from './PriceTablesEn';
import PointOfSaleEn from './PointOfSaleEn';
import FranchiseeEn from './FranchiseeEn';
import TablesEn from './TablesEn';
import ReportEn from './ReportEn';
import FiltersEn from './FiltersEn';

export default Object.assign({}, 
  PriceTablesEn,
  ButtonEn,
  PlanogramEn,
  RemoveDialogEn,
  PointOfSaleEn,
  FranchiseeEn,
  TablesEn,
  ReportEn,
  FiltersEn,
);