export default {
  filters: {
    labels: {
      calendar: "Calendar",
      months: {
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December"
      },
    },
    messages: {
      selectMonth: "Select a month"
    },
  },
}
