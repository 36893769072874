export default {
  tables: {
    headers: {
      id: "Id",
      pointOfSaleId: "ID PDV",
      actions: "Ações",
      customer: "Cliente",
      location: "Local",
      specificLocation: "Local específico",
      idSubEnvironment: "ID Sub Ambiente",
      subEnvironment: "Sub Ambiente",
      cnpj: "CNPJ",
      billing: "Faturamento (últimos 30 dias)",
      machines: "Qtd. de máquinas",
      stores: "Qtd. de lojas",
      nps: "Média de NPS",
      name: "Nome",
      type: "Tipo",
      lastUpdated: "Data de atualização",
      items: "Items",
      activate: "Ativar",
      remove: "Excluir",
      code: "Código",
      description: "Descrição",
      barCode: "Código de barra",
      category: "Categoria",
      cost: "Custo",
      price: "Preço",
      markUp: "MarkUp",
      responsible: "Responsável",
      applyPriceTableOnPointOfSale: "Aplicar tabela de preços em PDVs",
      removePriceTable: "Remover tabela de preços",
      visualizeFranchiseeEnvironment: "Visualizar Ambiente"
    },
    noData: "Sem resultados",
    none: "Sem dados"
  }
}