import i18n from "@/locales/i18n";
// noinspection SpellCheckingInspection

type ValidationFunction = (value: any, parameter?: any) => boolean | string;

export default class Validations {
  required: ValidationFunction = (v) => !!v || (i18n.t('requiredField') as string);
  nonEmpty: ValidationFunction = (v) => /\S/.test(v) || (i18n.t('insertValidValue') as string);
  float: ValidationFunction = (v) => !isNaN(v) || (i18n.t('insertValidValue') as string);
  integer: ValidationFunction = (v) => !v || /^\d+$/.test(v) || (i18n.t('numberInteger') as string);
  barcode: ValidationFunction = (v) => /^[A-Za-z0-9]*#*\*?$/.test(v) || "Insira um código de barras válido";
  commonCharacters: ValidationFunction = (v) => !v || /^[\w.\-+ ]*$/.test(v) || (i18n.t('commonCharacters') as string);
  commonCharactersExpanded: ValidationFunction = (v) => !v || /^(?!.*\s{2,})[A-Za-zÀ-ÖØ-öø-ÿ0-9\s()-]+$/.test(v) || (i18n.t('commonCharacters') as string);
  digitsAndDots: ValidationFunction = (v) => !v || /^[\d.]*$/.test(v) || "Insira caracteres válidos";
  positive: ValidationFunction = (v) => Number(v) > 0 || "Insira um valor válido";
  currency: ValidationFunction = (v) => !v || /^(0(\.\d{0,2})?|[1-9]\d*(\.\d{0,2})?)$/.test(v) || "Insira um valor válido";
  date: ValidationFunction = (v) => /^\d\d\/\d\d\/\d\d\d\d*$/.test(v) || "Insira uma data válida";
  passwordLenght: ValidationFunction = (v) => !v || /^.{8,}$/.test(v) || "A senha deve ter no mínimo 8 caracteres";
  minLength: ValidationFunction = (v, min) => !v || v.length >= min || (i18n.t('minLength', {min: min}) as string);
  emailPattern: ValidationFunction = (v) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(v) || "Email inválido";
  };
  phonePattern: ValidationFunction = (v) => {
    const pattern = /^[0-9]{10,11}$/;
    return pattern.test(v) || (i18n.t('phoneField') as string);
  };
  validateCnpj: ValidationFunction = (v) => {
    const allZeroPattern = /^0{14}$/;
    const nonNumericRegex = /\D/
    let valid = false;

    if (nonNumericRegex.test(v)) {
      return "CNPJ deve conter apenas números";
    }

    if (v.length !== 14) {
      return "CNPJ deve ter 14 dígitos";
    }

    if (allZeroPattern.test(v)) {
      return "CNPJ não pode ser apenas zeros";
    }

    // Code Generated by ChatGPT
    // Calculate the first verification digit
    let sum = 0;
    let weight = 2;
    for (let i = 11; i >= 0; i--) {
      sum += parseInt(v.charAt(i)) * weight;
      weight = weight === 9 ? 2 : weight + 1;
    }
    const verificationDigit1 = (sum % 11 < 2) ? 0 : (11 - (sum % 11));

    // Calculate the second verification digit
    sum = 0;
    weight = 2;
    for (let i = 12; i >= 0; i--) {
      sum += parseInt(v.charAt(i)) * weight;
      weight = weight === 9 ? 2 : weight + 1;
    }
    const verificationDigit2 = (sum % 11 < 2) ? 0 : (11 - (sum % 11));

    // Check if the verification digits match the ones provided by the user
    valid = (
      verificationDigit1 === parseInt(v.charAt(12)) &&
      verificationDigit2 === parseInt(v.charAt(13)));

    return valid || "CNPJ inválido";
  };
  requiredForMarketIntel: ValidationFunction = (v) => !!v || "Este campo é obrigatório para a Inteligência de Mercado";
  noWhitespaceRule: ValidationFunction = (v) => !/\s/.test(v) || (i18n.t('noWhitespace') as string);
  isCnpjOrCpf: ValidationFunction = (text) => {
    return /\d{3}\.\d{3}\.\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/.test(text);
  };
  phone: ValidationFunction = (v) => v.length === 15 || (i18n.t('invalidPhone') as string);
  positiveInteger: ValidationFunction = (v) => !v || (/^\d+$/.test(v) && v > 0) || i18n.t('isPositiveRules').toString();
  positiveFloat: ValidationFunction = (v) => (!isNaN(v) && v >= 0) || i18n.t('floatRules').toString();
}
