export default {
    removePickListsPickListTable: 'Remove pick lists',
    selectAtLeastOnePickList: 'Select at least one pick list',
    excelPickListTable: 'Excel',
    synchronizationWithManagementSystem: 'Synchronize pick-lists with the management system',
    noResultsPickListTable: 'No results',
    idPickListTable: 'ID Pick List',
    description: 'Description',
    primaryPickListTable: 'Primary',
    customerPickListTable: 'Customer',
    locationPickListTable: 'Location',
    specificLocationPickListTable: 'Specific Location',
    pointOfSalePickListTable: 'POS',
    machinePickListTable: 'Machine',
    updatedActionPickListTable: 'Updated in',
    syncWithOmiePickListTable: 'Synchronization with Omie management system',
    erpManagementSystem: 'ERP',
    removeConfirmationPickListTable: 'Are you sure you want to remove',
    pickListPickListTable: ' pick ',
    noPickListTable: 'No',
    removePickListTable: 'Remove',
    successRemovePickListTable: 'Pick list ',
    removeSuccessPickListTable: ' removed',
    errorRemovePickListTable: 'Unable to remove pick list',
    synchronizationStarted: 'A pick-list',
    synchronizationStartedPickListTable: ' is being synchronized with Omie and will be available in a few moments.',
    syncErrorPickListTable: 'Unable to sync pick-list',
    generateReportErrorPickListTable: 'Unable to generate report',
    createdSuccessPickListTable: ' created for POS ',
    createdErrorPickListTable: 'Unable to create the pick list for the POS ',
    syncSuccessPickListTable: 'The pick-lists are being synchronized with the AMLabs ERP.',
    syncErrorPickList: 'Unable to sync pick-lists.',
    editPickListTable: '(edited)',
    synchronizationPickListTable: 'Synchronization',
};