export default {
  pointOfSale: {
    data: {
      id: "Punto de venta {pointOfSaleId}",
      title: {
        pointOfSale: "Punto de Venta",
        pointsOfSale: "Puntos de Venta"
      },
    },
  },
};