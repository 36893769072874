export default {
  filters: {
    labels: {
      calendar: "Calendário",
      months: {
        january: "Janeiro",
        february: "Fevereiro",
        march: "Março",
        april: "Abril",
        may: "Maio",
        june: "Junho",
        july: "Julho",
        august: "Agosto",
        september: "Setembro",
        october: "Outubro",
        november: "Novembro",
        december: "Dezembro"
      }
    },
    messages: {
      selectMonth: "Selecione um mês",
    },
  },
}
