export default {
  tables: {
    headers: {
      id: "Id",
      pointOfSaleId: "ID PDV",
      actions: "Acciones",
      customer: "Cliente",
      location: "Ubicación",
      specificLocation: "Ubicación específica",
      idSubEnvironment: "ID del Sub Entorno",
      subEnvironment: "Sub Entorno",
      billing: "Facturación (últimos 30 días)",
      machines: "Cant. de máquinas",
      stores: "Cant. de tiendas",
      nps: "Promedio de NPS",
      name: "Nombre",
      type: "Tipo",
      lastUpdated: "Fecha de actualización",
      items: "Ítems",
      activate: "Activar",
      remove: "Eliminar",
      code: "Código",
      description: "Descripción",
      barCode: "Código de barras",
      category: "Categoría",
      cost: "Costo",
      price: "Precio",
      markUp: "Markup",
      responsible: "Responsable",
      applyPriceTableOnPointOfSale: "Aplicar tabla de precios en puntos de venta",
      removePriceTable: "Eliminar tabla de precios",
      visualizeFranchiseeEnvironment: "Visualizar Ambiente"
    },
    noData: "Sin resultados",
    none: "Sin datos"
  }
}