export default {
    Administrador: 'Administrador',
    'Administrador App': 'Administrador App',
    'Administrador PrivateLabel': 'Administrador PrivateLabel',
    Cadastros: 'Cadastros',
    Contabilidade: 'Contabilidade',
    'Edição Preços': 'Edição Preços',
    Gerente: 'Gerente',
    'Inventário às cegas e abastecimento': 'Inventário às cegas e abastecimento',
    Logistica: 'Logistica',
    Manager: 'Manager',
    'Notas Fiscais': 'Notas Fiscais',
    Segurança: 'Segurança',
    Síndico: 'Síndico',
    Suporte: 'Suporte',
    Telemetria: 'Telemetria',
    'TouchPay Abastecedor': 'TouchPay Abastecedor',
    Usuário: 'Usuário',
    Vendas: 'Vendas',
    'Visualização Planogramas': 'Visualização Planogramas',
    'Visualização PrivateLabel': 'Visualização PrivateLabel',
    'Visualização Transações': 'Visualização Transações',
    Mercurio: 'Mercurio',
    PushNotification: 'PushNotification',
    Video: 'Video',

    routes: {
        nameTransactions: 'Transações',
        nameTelemetry: 'Telemetria',
        nameConnectivityMaster: 'Conectividade',
        nameManagePos: 'Gerenciar Pontos de Venda',
        nameProductsMaster: 'Produtos',
        nameInventoryMaster: 'Estoques',
        namePurchases: 'Compras',
        nameOrders: 'Encomendas',
        nameNewPointOfSale: 'Novo Ponto de Venda',
        nameToEditPointOfSale: 'Editar Ponto de Venda',
        namePriceTables: 'Tabelas de Preços',
        namePriceTableItems: 'Produtos da Tabela de Preços',
        namePlanogramMaster: 'Planogramas',
        nameCharts: 'Relatórios de Vendas',
        nameTransactionsReports: 'Resumo Dinâmico de Vendas',
        nameInventoryReports: 'Resumo Dinâmico de Estoque',
        nameRuptures: 'Relatórios de Rupturas',
        nameLosses: 'Relatórios Operacionais',
        namePickLists: 'Pick Lists',
        nameReplenishment: 'Abastecimentos',
        nameAccessControl: 'Controle de Acesso',
        namePrivateLabel: 'Private Label',
        nameDiscountsMaster: 'Descontos',
        nameNewBox: 'Ativar Novo TouchPay',
        nameAlerts: 'Alertas',
        nameDownloads: 'Downloads',
        nameContracts: 'Ativações',
        nameSupport: 'Ajuda',
        nameReleaseNotes: 'Notas de Versão',
        nameAudits: 'Auditoria',
        nameTaxScenarios: 'Cenários Fiscais',
        nameTaxScenariosItems: 'Produtos do Cenário Fiscal',
        namePagSeguro: 'PagSeguro',
        nameSwagger: 'Swagger',
        nameSuspiciousMaster: 'Alertas de Desvios',
        nameUsers: 'Usuários',
        nameUsersFeedbacks: 'Avaliações',
        nameMobileUsers: 'Usuários App',
        nameNotifications: 'Notificações',
        nameCustomersMaster: 'Clientes',
        namePurchaseSuggestions: 'Sugestões de Compras',
        nameDashboard: 'Dashboard',
        nameMyBills: 'Minhas Faturas',
        namePromotionalLogos: 'Logos Promocionais',
        nameInvoicesMaster: 'Notas Fiscais (NFC-e)',
        nameViewFranchiseeWeb: 'Ambientes',
        nameTenantsFeedbacks: 'Feedbacks',
        nameCondominiumReports: 'Relatórios de Condomínios',
        nameMidasOrders: 'Novo Pedido',
        nameChangeOfOwnership: 'Aprovar Transferências',
        nameChangeOwnershipNewRequest: 'Transferir Ponto de Venda',
        nameViewTransferRequests: 'Ver Solicitações de Troca de Titularidade',
        nameCustomerProfile: 'Meu Perfil',
        redirect: 'dashboard',
        nameMissingProducts: 'Produtos não encontrados'
    },

    path: {
        nameTransactions: 'transacoes',
        nameTelemetry: 'telemetria',
        nameConnectivityMaster: 'conectividade',
        nameManagePos: 'ponto-de-vendas',
        nameProductsMaster: 'produtos',
        nameInventoryMaster: 'estoques',
        namePurchases: 'compras',
        nameOrders: 'encomendas',
        nameNewPointOfSale: 'pdv',
        nameToEditPointOfSale: 'pdv',
        namePriceTables: 'tabelas-precos',
        namePlanogramMaster: 'planograma',
        nameCharts: 'relatorios',
        nameTransactionsReports: 'resumo-vendas',
        nameInventoryReports: 'resumo-estoque',
        nameRuptures: 'relatorios-rupturas',
        nameLosses: 'relatorios-operacionais',
        namePickLists: 'picklist',
        nameReplenishment: 'abastecimentos',
        nameAccessControl: 'controle-acesso',
        namePrivateLabel: 'private-label',
        nameDiscountsMaster: 'descontos',
        nameNewBox: 'ativar',
        nameAlerts: 'alertas',
        nameDownloads: 'downloads',
        nameContracts: 'ativacoes',
        nameSupport: 'ajuda',
        nameReleaseNotes: 'release-notes',
        nameAudits: 'auditoria',
        nameTaxScenarios: 'cenarios-fiscais',
        namePagSeguro: 'pagseguro',
        nameSwagger: 'swagger',
        nameSuspiciousMaster: 'alertas-desvios',
        nameUsers: 'usuarios',
        nameUsersFeedbacks: 'feedback-usuarios',
        nameMobileUsers: 'usuarios-app',
        nameNotifications: 'push-notifications',
        nameCustomersMaster: 'Clientes',
        namePurchaseSuggestions: 'sugestoes-de-compras',
        nameDashboard: 'dashboard',
        nameMyBills: 'minhas-faturas',
        namePromotionalLogos: 'logos-promocionais',
        nameInvoicesMaster: 'notas-fiscais-nfce',
        nameViewFranchiseeWeb: 'visualizar-ambientes',
        nameTenantsFeedbacks: 'feedbacks',
        nameCondominiumReports: 'relatorios-condominios',
        nameMidasOrders: 'novo-pedido',
        nameChangeOfOwnership: 'aprovar-transferencias',
        nameChangeOwnershipNewRequest: 'transferir-ponto-venda',
        nameMissingProducts: 'produtos-nao-encontrados',
        nameCustomerProfile: 'meu-perfil',
    }
}
