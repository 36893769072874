
import Vue from 'vue';
import PointOfSalesMultipleSelector from '@/components/filters/PointOfSalesMultipleSelector.vue';
import { FormFilter, PromotionFilterDates } from '../promotions/Promotions.vue';
import DatePickerDynamic from '@/components/filters/DatePickerDynamic.vue';
import i18n from '@/locales/i18n';
import DiscountType from '@/components/filters/DiscountType.vue';
const _ = require('lodash');

const newForm = {
  pointOfSaleIds: [],
  discountType: null,
  startDate: '',
  endDate: '',
  timezoneOffset: 0
} as FormFilter;

export default Vue.extend({
  components: {
    'point-of-sale-selector': PointOfSalesMultipleSelector,
    'date-picker': DatePickerDynamic,
    DiscountType
  },
  props: {
    pointsOfSale: { type: Array, required: true },
    formFilter: { type: Object, required: true }
  },
  data() {
    return {
      valid: false,
      hasClearDate: false,
      form: newForm as FormFilter,
      dateRange: [] as string[],
      translations: {
        pointOfSale: i18n.t('pointsOfSale').toString(),
        period: i18n.t('promotion.period').toString(),
        promotionType: i18n.t('promotion.details.discount.type.text').toString(),
        chooseType: i18n.t('promotion.details.discount.choose').toString(),
        clear: i18n.t('componentClearButton').toString(),
        update: i18n.t('update').toString(),
      },
    };
  },
  watch: {
    dateRange(newRange: string[]) {
      if (newRange.length === 2) {
        this.form.startDate = newRange[0];
        this.form.endDate = newRange[1];
      }
    },
    formFilter(originalFilter: FormFilter) {
      if(!originalFilter) {
        return;
      }
      this.form = _.cloneDeep(originalFilter);
      this.dateRange = [this.form.startDate!, this.form.endDate!];
    }
  },
  mounted() {
    this.emitModification();
  },
  computed: {
    initialEndDate: (): string => PromotionFilterDates.initialEndDate(),
    initialStarDate: (): string => PromotionFilterDates.initialStartDate(),
  },
  methods: {
    emitModification() {
      if ((this.$refs.form as any).validate()) {
        this.$emit("modify", this.form);
      }
    },
    clearQuery() {
      Object.assign(this.form, newForm);
      this.clearDateRange();
      this.hasClearDate = true;
      this.emitModification();
    },
    clearDateRange() {
      this.dateRange = [];
      this.form.startDate = '';
      this.form.endDate = '';
    },
    updateDateRange() {
      if (this.dateRange && this.dateRange.length === 2) {
        this.form.startDate = this.dateRange[0] as any;
        this.form.endDate = this.dateRange[1] as any;
      }
    },
  },
});
