
import Vue from 'vue';
import i18n from '@/locales/i18n';
import moment, { Moment } from 'moment';
const defaultRange = 15;
/**
 * A date picker component where the provided range will be used to dynamic allow the days which the user will be able to select.
 */
export default Vue.extend({
  props: {
    clear: { type: Boolean, default: false },
    range: { type: Number, default: defaultRange },
    rules: { type: Array, default: () => [] },
    initialStartDate: { type: String, default: () => moment().subtract(defaultRange, 'days').format('YYYY-MM-DD') },
    initialEndDate: { type: String, default: () => moment().format('YYYY-MM-DD') },
    title: { type: String, default: () => i18n.t('filters.labels.calendar').toString() },
  },
  data() {
    return {
      dates: [] as string[],
      menu: false,
      formattedDates: [] as (string | null)[],
      formattedDatesText: '',
      valid: true,
      onStartDate: null as Moment | null,
      onEndDate: null as Moment | null
    };
  },
  watch: {
    clear() {
      this.clearDates();
      this.$emit('clearComplete');
    },
    dates(newValue, oldValue) {
      if(oldValue === newValue || newValue.length > 1) {
        return;
      }
      this.onDateChange(newValue);
    }
  },
  mounted() {
    this.dates = [this.initialStartDate, this.initialEndDate];
    this.updateDateRangeText();
  },
  methods: {
    handleDateChange(value: string[]) {
      this.validateDateRange();
      if (value.length === 2) {
        const [minDate, maxDate] = value;
        if (this.$moment(minDate).isAfter(maxDate)) {
          this.dates = [maxDate, minDate];
        } else {
          this.dates = [minDate, maxDate];
        }
      }
      this.$emit('input', this.dates);
    },
    clearDates() {
      this.dates = [];
      this.updateDateRangeText();
    },
    saveDate() {
      if (this.valid) {
        this.menu = false;
        this.updateDateRangeText();
        this.$emit('modify', this.dates);
      }
    },
    updateDateRangeText() {
      this.validateDateRange();
      this.formattedDates = [
        this.dates[0] ? this.$moment(this.dates[0]).format('DD/MM/YYYY') : null,
        this.dates[1] ? this.$moment(this.dates[1]).format('DD/MM/YYYY') : null
      ];
      this.formattedDatesText = this.valid
        ? `${this.formattedDates[0]} - ${this.formattedDates[1]}`
        : '';
    },
    validateDateRange() {
      const [startDate, endDate] = this.dates;
      if(startDate && startDate.length > 0 && endDate && endDate.length > 0) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    onDateChange(date: string | string[]) {
      if(Array.isArray(date)) {
        if(date.length === 0) {
          this.onStartDate = null;
          this.onEndDate = null;
        } else {
          this.onStartDate = this.$moment(date[0]).subtract(this.range, 'days');
          this.onEndDate = this.$moment(date[0]).add(this.range, 'days');
        }
      }
      if(this.onEndDate && this.onStartDate) {
        return this.$moment(date).isBetween(this.onStartDate, this.onEndDate, 'days', '[]');
      }
      return true;
    },
  }
});
