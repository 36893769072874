export default {
    promotionalLogos: {
        title: 'Logos Promocionais',
        apply: "Aplicar Logotipo Promocional",
        suggestion: "Sugestões",
        addLogo: "Adicionar novo logotipo",
        handleSuccess: "Logo atualizado para o PDV",
        handleError: "Não foi possível atualizar logo para o PDV",
        buttons: {
            delete: "Remover logotipo",
            apply: "Aplicar logotipos ao POS",
            updateLogo: "Atualizar logotipo",
        },
        
        block: "Bloquear Logotipo",
        blockSelected: "Bloquear alteração de logotipo para o POS selecionado",
        helpBlockLogo: {
            title: "Bloquear alteração de logotipo",
            description: "Ao habilitar esta opção, a alteração do logotipo no ponto de venda será restrita a usuários com permissão de administrador.",
        },
    },
};
