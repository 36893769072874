import ButtonPt from './ButtonPt';
import RemoveDialogPt from './RemoveDialogPt';
import PlanogramPt from './PlanogramPt';
import PriceTablesPt from './PriceTablesPt';
import PointOfSalePt from './PointOfSalePt';
import FranchiseePt from './FranchiseePt';
import TablesPt from './TablesPt';
import ReportPt from './ReportPt';
import FiltersPt from './FiltersPt';

export default Object.assign({}, 
  PriceTablesPt,
  ButtonPt,
  PlanogramPt,
  RemoveDialogPt,
  PointOfSalePt,
  FranchiseePt,
  TablesPt,
  ReportPt,
  FiltersPt,
);