export default {
  tables: {
    headers: {
      id: "Id",
      pointOfSaleId: "POS ID",
      actions: "Actions",
      customer: "Customer",
      location: "Location",
      specificLocation: "Specific Location",
      idSubEnvironment: "Sub Environment ID",
      subEnvironment: "Sub Environment",
      billing: "Billing (last 30 days)",
      machines: "Machine count",
      stores: "Store count",
      nps: "NPS Average",
      name: "Name",
      type: "Type",
      lastUpdated: "Update date",
      items: "Items",
      activate: "Activate",
      remove: "Delete",
      code: "Code",
      description: "Description",
      barCode: "Barcode",
      category: "Category",
      cost: "Cost",
      price: "Price",
      markUp: "Markup",
      responsible: "Responsible",
      applyPriceTableOnPointOfSale: "Apply price table to points of sale",
      removePriceTable: "Remove price table",
      visualizeFranchiseeEnvironment: "View Environment"
    },
    noData: "No results",
    none: "No data"
  }
}