export default {
  buttons: {
    search: "Buscar",
    cancel: "Cancelar",
    save: "Guardar",
    close: "Cerrar",
    apply: "Aplicar",
    create: "Crear",
    import: "Importar",
    add: "Añadir",
    excel: "Excel",
    yes: "Sí",
    no: "No",
    finish: "Finalizar",
    remove: "Eliminar",
    activate: "Activar"
  }
}
