
import Vue from 'vue';
import { AcquisitionOrders } from '@/api/agent';
import { AcquisitionOrderRequestDto, FilledCustomerInfoDto, CustomerFullInfoDto, PaymentProcessorDto, CatalogItem, DeliveryAddress } from '@/types/MidasProductsOrders'
import StateSelectorFilter from '@/components/filters/StateSelectorFilter.vue';
import TokenHelp from '@/views/midasOrders/Helps/TokenHelp.vue';
import ActivationTokenHelp from '@/views/midasOrders/Helps/ActivationTokenHelp.vue';

const defaultEnvironmentContractInfo = {
  contractId: 0,
  billingDocumentId: null,
  customerInfo: {
    fantasyName: null,
    email: null,
    city: null,
    cep: null,
    address: null,
    addressNumber: null,
    addressComplement: null,
    brazilState: null,
    phone: null,
  } as FilledCustomerInfoDto,
  paymentProcessorDto: {
    email:  null,
    activationCode:  null,
    apiToken:  null,
  } as PaymentProcessorDto
} as CustomerFullInfoDto

export default Vue.extend({
  components: {
    StateSelectorFilter,
    TokenHelp,
    ActivationTokenHelp
  },
  props: {
    parentOrderProducts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      environmentContractInfo: { ...defaultEnvironmentContractInfo },
      loading: false,
      valid: {
        order: false,
        newPaymentProcessor: false,
        newCustomerAddress: false
      },
      finalOrderProducts: [] as (CatalogItem & {selectedQuantity: number})[],

      // variables for the paymentProcessors radio buttons and dialog
      paymentProcessors: [] as PaymentProcessorDto[],
      selectedPaymentProcessorIndex: 0,
      newPaymentProcessorDialog: false,
      newPaymentProcessor: {
        email: '',
        activationCode: '',
        apiToken: ''
      } as PaymentProcessorDto,

      // variables for the customerAddresses radio buttons and dialog
      deliveryAdresses: [] as (DeliveryAddress | null)[],
      selectedDeliveryAddressIndex: 0,
      newCustomerAddress: {
        city: "",
        cep: "",
        address: "",
        addressNumber: null,
        addressComplement: null as string | null,
        brazilState: "",
      } as FilledCustomerInfoDto,
      newCustomerAddressDialog: false
    }
  },
  mounted() {
    this.getEnvironmentContractInfo();
    this.finalOrderProducts = this.parentOrderProducts as any[];
  },
  methods: {
    async getEnvironmentContractInfo() {
      const params = {
        tenantId: (this as any).$auth.user().tenantId,
        groupId: (this as any).$auth.user().tenantGroupId
      }
      try {
        const response = await AcquisitionOrders.getEnvironmentContractInfo(params);
        this.environmentContractInfo = Object.assign(this.environmentContractInfo, response);
      } catch (error) {
        (this.$refs.feedback as any).handleError(error);
        this.environmentContractInfo = { ...defaultEnvironmentContractInfo }
      } finally {
        this.initializePaymentProcessorsArray();
        this.initializeCustomerInfosArray();
      }
    },
    async sendOrder() {
      if ((this.$refs.form as any).validate()) {

        const selectedAddress = this.deliveryAdresses[this.selectedDeliveryAddressIndex]
        if (selectedAddress != null && Object.entries(selectedAddress as DeliveryAddress)
          .filter(([key, ]) => key !== 'addressComplement')
          .some(([, value]) => value === null)
        ) {
          (this.$refs.feedback as any).handleError(this.$t("missingAddressInformationError"));
          return;
        }

        const paymentProcessorDto = this.paymentProcessors[this.selectedPaymentProcessorIndex]

        if (paymentProcessorDto == undefined) {
          (this.$refs.feedback as any).handleError(this.$t("missingPaymentProcessorError"));
          return;
        }

        const orderItems = this.finalOrderProducts.map(product => {
          return {
            quantity: product.selectedQuantity,
            serviceType: product.serviceType
          }
        })

        const params: AcquisitionOrderRequestDto = {
          orderItems: orderItems,
          tenantId: (this as any).$auth.user().tenantId,
          groupId: (this as any).$auth.user().tenantGroupId,
          enableInvoiceIssuance: false,
          fantasyName: this.environmentContractInfo.customerInfo.fantasyName,
          email: this.environmentContractInfo.customerInfo.email,
          phoneNumber: `+55${this.environmentContractInfo.customerInfo.phone}`,
          deliveryAddress: selectedAddress,
          paymentProcessorDto: paymentProcessorDto
        }

        this.loading = true;
        try {
          await AcquisitionOrders.postAcquisitionOrder(params);
          this.$emit('orderSent');
        } catch (error) {
          (this.$refs.feedback as any).handleError(error);
        } finally {
          this.loading = false;
        }
      }
    },
    pushNewPaymentProcessor() {
      (this.$refs.newPaymentProcessorForm as any).validate();
      const newPaymentProcessor = Object.assign({}, this.newPaymentProcessor);
      this.paymentProcessors.push(newPaymentProcessor);
      this.newPaymentProcessorDialog = false;
    },
    pushNewCustomerAddress() {
      (this.$refs.newCustomerAddressForm as any).validate();
      const newCustomerAddress = Object.assign({}, this.newCustomerAddress);
      this.deliveryAdresses.push(newCustomerAddress);
      this.newCustomerAddressDialog = false;
    },
    initializePaymentProcessorsArray() {
      if (Object.values(this.environmentContractInfo.paymentProcessorDto).some(value => value === null)){
        this.paymentProcessors = [];
      }
      else {
        this.paymentProcessors = [Object.assign({}, this.environmentContractInfo.paymentProcessorDto)];
      }
    },
    initializeCustomerInfosArray() {
      const deliveryAddressFromEnvironment = {
        city: this.environmentContractInfo.customerInfo.city,
        cep: this.environmentContractInfo.customerInfo.cep,
        address: this.environmentContractInfo.customerInfo.address,
        addressNumber: this.environmentContractInfo.customerInfo.addressNumber,
        addressComplement: this.environmentContractInfo.customerInfo.addressComplement,
        brazilState: this.environmentContractInfo.customerInfo.brazilState,
      } as DeliveryAddress;

      this.deliveryAdresses = Object.values(deliveryAddressFromEnvironment).some(value => value !== null)
       ? ([Object.assign({}, deliveryAddressFromEnvironment)]) : [];

      this.deliveryAdresses.push(null);
    }
  },
})
