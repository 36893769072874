
import Vue from 'vue';
import DiscountType from '@/components/filters/DiscountType.vue';
import DatePicker from '@/components/filters/DatePicker.vue';
import ClearButton from '@/components/buttons/ClearButton.vue';
import UpdateButton from '@/components/buttons/UpdateButton.vue';
import moment from 'moment';

export default Vue.extend({
  data() {
    return {
      valid: false,
      form: {
        minDate: moment().add(-30, 'days').format('YYYY-MM-DD'),
        maxDate: moment().format('YYYY-MM-DD'),
        date: [],
        type: null,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      clearDate: false,
    };
  },
  mounted() {
    this.$emit('apply-filters', this.form);
  },
  methods: {
    applyFilters() {
      this.$emit('apply-filters', this.form);
    },
    filterDateChanged(date : any ) {
      [this.form.minDate, this.form.maxDate] = date
    },
    clearFilters() {
      this.form.date = [];
      this.clearDate = true;
      this.form.type = null;
    }
  },
  components: {
    DiscountType,
    DatePicker,
    ClearButton,
    UpdateButton
  }
});
