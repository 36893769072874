export default {
    promotionalLogos: {
        title: 'Logos Promocionales',
        apply: "Aplicar Logotipo Promocional",
        suggestion: "Sugerencias",
        addLogo: "Agregar nuevo logotipo",
        handleSuccess: "Logo actualizado para el PDV",
        handleError: "No se pudo actualizar el logo para el PDV",
        buttons: {
            delete: "Eliminar logotipo",
            apply: "Aplicar logotipos al POS",
            updateLogo: "Actualizar logotipo",
        },
        
        block: "Bloquear Logotipo",
        blockSelected: "Bloquear cambio de logotipo para el POS seleccionado",
        helpBlockLogo: {
            title: "Bloquear cambio de logotipo",
            description: "Al habilitar esta opción, el cambio de logotipo en el punto de venta estará restringido a usuarios con permisos de administrador.",
        },
    },
};
